<template>
  <div :class="s.handlerWraper" ref="doctorConfirm">
    <el-dialog
      title="问诊结果"
      :visible.sync="isOver"
      width="80%"
      top="2vh"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div :class="[s.userInfo, s.box]">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="患者信息" name="1">
            <div :class="s.formBody" v-show="patientInfo.uuid">
              <div :class="s.boxCard">
                <ul>
                  <li
                    v-for="(item, index) in fPatientInfo"
                    :key="index"
                    :class="[s.text, s.item]"
                  >
                    <span>{{ item.label }}</span
                    >{{ item.value }}
                  </li>
                </ul>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div :class="[s.box, s.fileList]" v-if="zaAttachmentList.length">
        <h3>转诊文件列表</h3>

        <ul>
          <li
            v-for="(item, index) in zaAttachmentList"
            :key="item.id"
            style="margin-bottom: 4px"
          >
            <i class="el-icon-folder-opened"></i>
            <a :href="item.attachmentUrl" target="_blank">
              转诊文件{{ index + 1 }}
            </a>
          </li>
        </ul>
      </div>
      <div :class="[s.box, s.judge, activeNames.length ? '' : s.cllo]">
        <h3>诊断报告</h3>
        <div :class="s.judgeForm">
          <el-form
            ref="Form"
            :model="form"
            label-width="100px"
            :rules="rules"
            :disabled="form.isEnd"
          >
            <el-form-item label="是否复诊" prop="subsequentFlag">
              <el-radio v-model="form.subsequentFlag" :label="true"
                >是</el-radio
              >
              <el-radio v-model="form.subsequentFlag" :label="false"
                >否</el-radio
              >
            </el-form-item>

            <el-form-item label="是否意外" prop="isAccident">
              <el-radio v-model="form.isAccident" :label="true">是</el-radio>
              <el-radio v-model="form.isAccident" :label="false">否</el-radio>
            </el-form-item>
            <el-form-item label="是否本人" prop="isSelf">
              <el-radio v-model="form.isSelf" :label="true">是</el-radio>
              <el-radio v-model="form.isSelf" :label="false">否</el-radio>
            </el-form-item>
            <el-form-item label="是否既往症" prop="isAccident">
              <el-radio v-model="form.isAnamnesis" :label="true">是</el-radio>
              <el-radio v-model="form.isAnamnesis" :label="false">否</el-radio>
            </el-form-item>

            <el-form-item
              label="疾病分类"
              prop="diseasesType"
              :class="s.flexBox"
            >
              <el-select v-model="form.diseasesType">
                <el-option
                  v-for="item in diseasesTypeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="疾病类型"
              prop="diagCodeType"
              :class="s.flexBox"
            >
              <el-select v-model="form.diagCodeType">
                <el-option
                  v-for="item in diagCodeTypeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="现病史" prop="presentIllness">
              <el-input
                type="textarea"
                v-model="form.presentIllness"
              ></el-input>
            </el-form-item>
            <el-form-item label="诊断结果" prop="result">
              <el-input type="textarea" v-model="form.result"></el-input>
            </el-form-item>
            <el-form-item label="诊断建议" prop="suggest">
              <el-input type="textarea" v-model="form.suggest"></el-input>
            </el-form-item>
            <el-form-item label="是否开药" prop="recipeFlag" class="item-two">
              <el-radio
                v-model="form.recipeFlag"
                :label="true"
                :disabled="
                  !form.subsequentFlag || this.patientInfo.isPrescribe === 'N'
                "
                >是</el-radio
              >
              <el-radio v-model="form.recipeFlag" :label="false">否</el-radio>
            </el-form-item>
            <div v-if="form.recipeFlag">
              <div :class="s.handleMedInfo">
                <h3>选药方式</h3>
                <div :class="s.medBox">
                  <h3>
                    确定药品信息<span
                      >一次检索后，一旦添加药品，无法重复查询，需删除后重查</span
                    >
                  </h3>
                  <div :class="s.everyRaw">
                    <h4>配送形式</h4>
                    <el-form-item class="item-two">
                      <el-radio
                        v-model="ZADRUGLIST.userDistributeType"
                        :disabled="!ZADRUGLIST.distributeType.includes('1')"
                        :label="'1'"
                        >快递</el-radio
                      >
                      <el-radio
                        v-model="ZADRUGLIST.userDistributeType"
                        :disabled="!ZADRUGLIST.distributeType.includes('2')"
                        :label="'2'"
                        >同城配送</el-radio
                      >
                      <el-radio
                        v-model="ZADRUGLIST.userDistributeType"
                        :disabled="!ZADRUGLIST.distributeType.includes('3')"
                        :label="'3'"
                        >到店自取</el-radio
                      >
                    </el-form-item>
                  </div>
                  <div :class="s.everyRaw">
                    <h4>选药方式</h4>
                    <el-checkbox-group :value="['1']" :class="s.checkBoxItem">
                      <el-checkbox label="1" checked>按药品通用名</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div :class="s.everyRaw">
                    <h4>添加药品名称</h4>
                    <div :class="s.checkBoxItem">
                      <el-input
                        v-model="ZADRUGLISTPRE"
                        placeholder="请添加药品内容"
                        style="width: 273px"
                        clearable
                      ></el-input>
                      <el-button
                        plain
                        style="
                          margin-left: 8px;
                          color: #31b4b0;
                          border: 1px solid #31b4b0;
                        "
                        @click="addZAlist"
                        >添加</el-button
                      >
                    </div>
                  </div>
                  <div :class="s.everyRaw">
                    <h4>待检索药品</h4>
                    <div :class="[s.checkBoxItem, s.borderBox]">
                      <el-tag
                        closable
                        @close="handleCloseTag(item)"
                        v-for="(item, index) in ZADRUGLIST.drugNameList"
                        :key="index"
                        color="#fff"
                        class="tags"
                        >{{ item }}</el-tag
                      >
                    </div>
                  </div>

                  <div :class="s.everyRaw">
                    <h4></h4>
                    <div :class="s.grayInfo">
                      已选择药品{{ ZADRUGLIST.drugNameList.length || 0 }}件
                    </div>
                  </div>

                  <el-button
                    style="
                      margin: 20px auto 0;
                      background: #31b4b0;
                      border-radius: 4px;
                      color: #fff;
                      display: block;
                    "
                    @click="queryZAdrug"
                    >药房检索</el-button
                  >
                </div>
              </div>
              <!-- 检索结果 -->
              <div :class="s.medBox">
                <h3>检索结果<span></span></h3>

                <el-table
                  :data="allDrugList"
                  style="width: 100%; text-align: center"
                  header-row-class-name="textCenter"
                >
                  <el-table-column prop="drugCommonName" label="药品通用名">
                  </el-table-column>
                  <el-table-column prop="drugDosageForm" label="剂型">
                  </el-table-column>
                  <el-table-column prop="drugManufactorName" label="厂家">
                  </el-table-column>
                  <el-table-column prop="drugSpecifications" label="规格">
                  </el-table-column>
                  <el-table-column prop="drugSellingPrice" label="售价">
                  </el-table-column>
                  <el-table-column prop="drugClassification" label="药品类型">
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        @click="handleEdit(scope.$index, scope.row)"
                        >添加</el-button
                      >
                    </template></el-table-column
                  >
                </el-table>
              </div>
              <div :class="[s.grayInfo, s.fixMargin]">
                可选药品清单（单处方限5种药品）
              </div>

              <!-- 可添加药品 -->
              <div :class="s.medBox">
                <el-table :data="choosenDrugList" style="width: 100%">
                  <el-table-column prop="drugCommonName" label="药品通用名">
                  </el-table-column>
                  <el-table-column prop="drugDosageForm" label="剂型">
                  </el-table-column>
                  <el-table-column prop="drugManufactorName" label="厂家">
                  </el-table-column>
                  <el-table-column prop="drugSpecifications" label="规格">
                  </el-table-column>
                  <el-table-column prop="drugSellingPrice" label="售价">
                  </el-table-column>
                  <el-table-column prop="drugClassification" label="药品类型">
                  </el-table-column>

                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        @click="handleDelete(scope.$index, scope.row)"
                        >删除</el-button
                      >
                    </template></el-table-column
                  >
                </el-table>
              </div>
              <div :class="[s.grayInfo, s.fixMargin]">
                已选择药品{{ choosenDrugList.length || 0 }}件,还可添加{{
                  5 - choosenDrugList.length
                }}件药品
              </div>
            </div>
            <div :class="s.btns">
              <el-button
                style="
                  background: #31b4b0;
                  border-radius: 4px;
                  color: #fff;
                  display: block;
                "
                @click="onSubmit"
                >确认提交</el-button
              >

              <a v-show="prescribeUrl" :href="prescribeUrl" target="_blank"
                >处方地址</a
              >
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="开具处方签"
      :visible.sync="dialogTableVisible"
      width="80%"
      top="2vh"
      @close="iframeClose"
    >
      <iframe
        :src="mSrc"
        ref="iframe"
        style="width: 100%; height: 700px"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import html2canvas from 'html2canvas';
import debounce from 'lodash/debounce';

import cloneDeep from 'lodash/cloneDeep';
// import fackData from './fackData';
const DICTIONARIES = {
  name: '姓名',
  idCard: '身份证号',
  chiefComplaint: '问诊主诉',
  weight: '体重',
  allergicHistory: '过敏史',
  medicalHistory: '过往病史',
  medicalRecord: '病例资料',
  other: '其他',
  mobile: '手机',
  relation: '与用户关系',
  clinicDepartment: '就诊科室',
  presentHistory: '现病史',
  lactationFlag: '哺乳期',
  gestationFlag: '妊娠期',
  age: '年龄',
  fGender: '性别', // 0：男 1：女 2：保密
  appDesc: '渠道',
  businessType: {
    SP00000001: '视频问诊众安药房',
    TW00000002: '图文问诊众安药房',
    TW00000003: '图文问诊蚂蚁药房',
  },
  //    avatar: '患者头像'
};
const apiUrl = {
  // queryZAdrug: '/za/drugs/queryThirdDrugsByNames', // 查询众安第三方药品列表
  queryMBdrug: '/drugs/queryDrugsByNames', // 查询缮瓷第三方药品列表
  userDetail: '/trtc/detail', // 患者信息
  diaGnose: '/trtc/diagnose', //诊断结果提交
  base64Url: '/sys/oss/upload/base64', //base获取图片地址
  outputMedicine: '/prescription/prescribe', //开药
};
export default {
  props: {
    patientInfo: {
      type: Object,
      required: true,
    },
    isOver: {
      type: Boolean,
      required: true,
    },
    fromOrigin: {
      type: String,
      required: true,
    },
  },
  computed: {
    s() {
      return this.$style;
    },
    fPatientInfo() {
      return Object.keys(DICTIONARIES).map((item) => {
        if (item === 'businessType') {
          return {
            label: '业务渠道',
            value: DICTIONARIES.businessType[`${this.patientInfo[item]}`],
          };
        }
        return {
          label: DICTIONARIES[item],
          value: this.patientInfo[item],
        };
      });
    },
    ...mapState({
      groupID: (state) => {
        return state.groupId;
        // return 'f6f08a2d-6479-482e-8ba3-8279a0df2c9d';
      },
    }),
  },
  watch: {
    async groupID(val, oldValue) {
      if (!val) {
        this.$refs['Form'].resetFields();
        this.form = Object.assign({}, this.$options.data().form);
        return;
      }

      let deepForm = cloneDeep(this.form);
      //存在多个会话进行数据存储
      if (oldValue) {
        let findIndex = this.copyForms.findIndex((i) => i.id == oldValue);
        this.copyForms.splice(findIndex, 0, {
          id: oldValue,
          data: deepForm,
        });
      }
      //表单二次渲染
      if (this.copyForms.length) {
        let form = this.copyForms.find((i) => i.id == val);
        if (form) {
          this.form = form.data;
        } else {
          this.$refs['Form'].resetFields();
        }
      }

      // this.getUserInfo();
    },
    'form.subsequentFlag': function (val) {
      if (!val) {
        this.form.recipeFlag = false;
      }
    },
  },
  data() {
    return {
      activeNames: ['1'],
      copyForms: [],
      dialogTableVisible: false,
      mSrc: '',
      diseasesTypeOption: [
        {
          value: '1',
          label: '普通',
        },
        {
          value: '2',
          label: '急性病',
        },
        {
          value: '3',
          label: '慢性病',
        },
        {
          value: '9',
          label: '未知',
        },
      ], // 疾病分类（1：普通，2：急性病，3：慢性病，9：未知）

      diagCodeTypeOption: [
        {
          value: 'ICD10',
          label: '西医',
        },
        {
          value: 'GB95',
          label: '中医',
        },
      ], //  疾病类型（ICD10：西医，GB95：中医）
      rules: {
        result: [{ required: true, message: '诊断结果', trigger: 'blur' }],
        suggest: [{ required: true, message: '诊断建议', trigger: 'blur' }],
        presentIllness: [
          { required: true, message: '现病史', trigger: 'blur' },
        ],
        isSelf: [{ required: true, message: '是否本人' }],
        isAnamnesis: [{ required: true, message: '是否既往症' }],
        isAccident: [{ required: true, message: '是否意外' }],
        diseasesType: [{ required: true, message: '疾病分类' }],
        diagCodeType: [{ required: true, message: '疾病类型' }],
        subsequentFlag: [{ required: true, message: '是否复诊' }],
      },
      ZADRUGLISTPRE: '',
      ZADRUGLIST: {
        // 查众安接口
        drugNameList: [],
        // currentPage: 1,
        // pageSize: 10,
        distributeType: [], // 1:B2C；2:O2O; 3:线下自取
        userDistributeType: '',
      },

      allDrugList: [],
      choosenDrugList: [],
      fileSrc: '',
      form: {
        result: '',
        suggest: '',
        recipeFlag: false,
        subsequentFlag: true,
        diseasesType: '1',
        diagCodeType: 'ICD10',
        presentIllness: '', // 现病史
        isSelf: true, // 是否本人
        isAnamnesis: false, // 是否既往症
        isAccident: false, // 是否意外
        isEnd: false, // 是否已经诊断
      },
      zaAttachmentList: [],
      prescribeUrl: '', //开药链接
    };
  },
  methods: {
    iframeClose() {
      this.dialogTableVisible = false;
      this.mSrc = '';
    },
    match(type) {
      //1-本人,2-配偶,3-子女,4-父母,5-其他
      let relation = '';
      switch (type) {
        case '1':
          relation = '本人';
          break;
        case '2':
          relation = '配偶';
          break;
        case '3':
          relation = '子女';
          break;
        case '4':
          relation = '父母';
          break;
        default:
          relation = '其他';
          break;
      }
      return relation;
    },
    flatten(arr) {
      return JSON.parse(arr.replace(/'/g, '')).map((item) => String(item));
    },
    convertToImage(container, options = {}) {
      // 设置放大倍数
      const scale = window.devicePixelRatio;

      // 传入节点原始宽高
      const width = container.offsetWidth;
      const height = container.offsetHeight;
      // html2canvas配置项
      const ops = {
        scale,
        width,
        height,
        useCORS: true,
        allowTaint: false,
        ...options,
      };

      return html2canvas(container, ops).then((canvas) => {
        // 返回图片的二进制数据
        console.log(canvas.toDataURL('image/png'));
        return canvas.toDataURL('image/png');
      });
    },
    addZAlist() {
      console.log(this.ZADRUGLISTPRE);
      if (this.ZADRUGLISTPRE && this.ZADRUGLIST.drugNameList.length < 5) {
        this.ZADRUGLIST.drugNameList.push(this.ZADRUGLISTPRE);
        this.ZADRUGLISTPRE = '';
      }
    },
    async queryZAdrug() {
      let reqUrl = apiUrl.queryMBdrug;

      // this.choosenDrugList = [];
      const { uuid, inquiryNo, businessType } = this.patientInfo;
      const postData = {
        ...this.ZADRUGLIST,
        inquiryNo,
        businessType,
        distributeType: this.ZADRUGLIST.userDistributeType,
        uuid,
      };
      const { data: res } = (await this.$http.post(reqUrl, postData)) || {};
      const { code, msg, data = [] } = res;
      if (code !== 0) {
        this.$notify({
          title: '错误',
          message: msg,
          type: 'error',
        });
        return;
      }
      console.log(data);
      this.allDrugList = data;
      this.ZADRUGLIST.drugNameList = [];
    },
    handleEdit(index, row) {
      row.num = 1;
      this.choosenDrugList.indexOf(row) === -1 &&
        this.choosenDrugList.push(row);
    },
    handleDelete(index, row) {
      this.choosenDrugList.splice(index, 1);
    },
    handleCloseTag(tag) {
      this.ZADRUGLIST.drugNameList.splice(
        this.ZADRUGLIST.drugNameList.indexOf(tag),
        1
      );
    },
    objHandle(obj) {
      for (let key in obj) {
        if (obj[key] === '') {
          delete obj[key];
        }
      }
      return obj;
    },
    onSubmit: debounce(
      async function () {
        this.$refs['Form'].validate(async (valid) => {
          if (!valid) {
            this.$notify({
              title: '错误',
              message: '请检查诊断信息是否已完成',
              type: 'error',
            });

            return;
          }
          const {
            result,
            suggest,
            recipeFlag,
            subsequentFlag,
            isSelf,
            isAnamnesis,
            isAccident,
          } = this.form;
          const { uuid, name, chiefComplaint, doctorId } = this.patientInfo;
          if (recipeFlag && !this.choosenDrugList.length) {
            this.$message({
              message: '请选择药品',
              type: 'error',
            });
            return;
          }
          // 删除本地
          let index = this.copyForms.findIndex((i) => i.id == this.groupID);
          if (index > -1) {
            this.copyForms.splice(index, 1);
          }
          const loading = this.$loading({
            lock: true,
            text: '提交中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          const postData = {
            ...this.objHandle(this.form),
            subsequentFlag: subsequentFlag ? 'Y' : 'N', //是否复诊
            uuid, //诊断唯一标识
            result, //诊断结果
            suggest, //诊断建议
            doctorId, //医生id
            //   doctorName: '', //医生姓名
            //   doctorAvatar: '', //医生头像
            //   doctorDepartment: '', //医生科室
            // inTime: '', //问诊时间
            //   prescriptionReport: '', //处方报告
            // evaluate: '', //评价
            chiefComplaint, //主诉
            name, //患者姓名
            recipeFlag: recipeFlag ? 'Y' : 'N', //是否开具处方 Y:是 N
            isSelf: isSelf ? 'Y' : 'N', // 是否本人
            isAnamnesis: isAnamnesis ? 'Y' : 'N', // 是否既往症
            isAccident: isAccident ? 'Y' : 'N', // 是否意外
            // rimg: '', //处方图片
            // rsn: '', //处方号
            // recipeTime: '', //处方开具时间
            noticeType: 3, // 正常结束: 1、异常结束: 2，诊断及处方：3
            distributeType: this.ZADRUGLIST.userDistributeType,
          };

          if (recipeFlag) {
            const flag = await this.prescription()
              .then(() => {
                loading.close();
                return true;
              })
              .catch((error) => {
                loading.close();
                console.log(error);
                return false;
              });
            if (!flag) return;
          }

          const res = await this.$http
            .post(apiUrl.diaGnose, postData)
            .catch(() => loading.close());
          loading.close();
          if (res.data.code == '0') {
            this.$message({
              message: '提交成功',
              type: 'success',
            });

            this.$emit('allOver', true);

            if (!recipeFlag) {
              this.$store.commit('updateDiagnosisList', uuid);
            }
            //提交后重置表单，删除本地本分

            this.resetFormData();

            this.$refs['Form'].resetFields();
            this.$emit('allOver', true);
            this.$emit('closeAgree', true);
            // this.getUserInfo();
            return;
          }
          this.$message({
            message: res.data.msg,
            type: 'error',
          });
        });
      },
      3000,
      { leading: true, trailing: false }
    ),
    resetFormData() {
      // this.$refs['Form'].resetFields();

      this.allDrugList = [];
      this.form.isEnd = false;
      this.choosenDrugList = [];
      this.prescribeUrl = null;
      // Object.assign(this.$data, this.$options.data.call(this));
    },
    async prescription() {
      const { uuid } = this.patientInfo;

      await this.getImage();
      let postData = {
        uuid,
        drugsList: this.choosenDrugList,
        fileList: [this.fileSrc],
        // fileList: [
        //     'https://img2.baidu.com/it/u=3532006139,510454224&fm=26&fmt=auto&gp=0.jpg',
        // ],
        avatar:
          'https://store.saastest.miaoins.com/mius/20210628/4f4da94a68c54e718b76d01bfc9e4789.png',
      };
      const res = await this.$http.post(apiUrl.outputMedicine, postData);
      if (~~res.data.code !== 0) {
        this.$notify({
          title: '错误',
          message: res.data.msg,
          type: 'error',
        });
        throw new Error(`开药失败`);
      }
      let url = res.data.data.url;
      // let newWindow = window.open();
      // newWindow.location.href = url;

      // this.dialogTableVisible = true;
      // this.mSrc = url;
      window.open(url);
    },
    async getImage() {
      const dom = this.$refs.doctorConfirm;
      this.toDataURL = await this.convertToImage(dom);
      const res = await this.$http.post(apiUrl.base64Url, {
        base64: this.toDataURL.substring(22),
        extension: 'png',
      });
      this.fileSrc = res.data.data.src;
    },
  },
};
</script>

<style lang="scss">
.el-collapse-item__header {
  font-size: 14px;
  font-weight: bold;
  padding-left: 16px;
  background: #fbfbfb;
}
.tags {
  margin: 0 8px;
  height: 32px;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 20px;

  .el-icon-close {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}
.el-table td,
.el-table th {
  text-align: center;
}
</style>
<style module lang="scss" scoped>
h3,
h4,
h5 {
  margin: 0 auto;
}
.addORreduce {
  width: 100%;
  height: 25px;
  background: #f6f6f6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 4px;
  box-sizing: border-box;
  i {
    display: flex;
    width: 18px;
    height: 18px;
    background: #ffffff;
    border-radius: 4px;
    position: relative;
  }
  .reduce {
    &::before {
      display: block;
      content: '';
      width: 7px;
      height: 1px;
      background-color: #a9a9a9;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .add {
    &::before {
      display: block;
      content: '';
      width: 7px;
      height: 1px;
      background-color: #a9a9a9;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      display: block;
      content: '';
      width: 7px;
      height: 1px;
      background-color: #a9a9a9;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
.handlerWraper {
  height: 85vh;
  overflow: auto;
  width: 80%;
  :global {
    .el-textarea__inner {
      height: 96px;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
    }
    .el-form-item__label {
      color: #666;
      width: auto !important;
    }
    .el-form-item__content,
    .el-form-item {
      margin: 0 !important;
      width: auto;
    }
  }

  .box {
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px #ecf2f2;
    border-radius: 6px 6px 6px 6px;
    margin-bottom: 16px;
    h3 {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4a4a4a;
      line-height: 40px;
      text-indent: 18px;
      background: #fbfbfb;
    }
  }
  .userInfo {
    // height: 234px;

    .boxCard {
      width: 100%;
      ul {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        width: 33.33%;
        list-style: none;
        font-size: 14px;
        line-height: 28px;
        span {
          color: #666;
          display: inline-block;
          width: 70px;
          text-align: right;
          margin-right: 20px;
        }
      }
    }
  }
  .judge {
    height: 455px;
    &.cllo {
      height: 655px;
    }
    .judgeForm {
      height: 100%;
      padding: 22px 33px 80px;
      box-sizing: border-box;
      overflow-y: scroll;
      :global {
        .el-form-item {
          margin-bottom: 10px !important;
        }
      }
    }
  }
  .handleMedInfo {
    h3 {
      background: #fff;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4a4a4a;
    }
  }
  .medBox {
    margin: 0 0px 17px;
    padding-bottom: 30px;
    border: 1px solid #e7e7e7;
    h3 {
      height: 40px;
      background: #fbfbfb;
      line-height: 40px;
      // padding: 0 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f0;
      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .everyRaw {
      display: flex;
      align-items: center;
      margin-top: 20px;

      h4 {
        margin: 0;
        display: inline-block;
        width: 110px;
        text-align: right;
        margin-right: 14px;
        color: #666666;
      }
      .checkBoxItem {
        width: 100%;
        :global {
          .el-checkbox {
            width: 150px;
          }
        }
      }
    }
  }
  .borderBox {
    border: 1px solid #e5e5e5;
    height: 44px;
    margin-right: 30px;
    display: flex;
    align-items: center;
  }
  .grayInfo {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999 !important;
    margin-left: -15px;
  }
  .searchBox {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    padding-left: 22px;
    height: 40px;
    margin-top: 14px;
    margin-bottom: 11px;
    .searchBoxInput {
      width: 448px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #e5e5e5;
      margin: 0px 8px 0px 6px;
    }
  }
  .fixMargin {
    margin: -6px 0 24px;
  }
  .flexBox {
    margin-bottom: 10px !important;
    :global {
      .el-form-item__content {
        margin-left: 0px !important;
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px auto 100px;
    a {
      background: #31b4b0;
      border-radius: 4px;
      color: #fff;
      display: block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid #dcdfe6;
      color: #606266;
      -webkit-appearance: none;
      text-align: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      -webkit-transition: 0.1s;
      transition: 0.1s;
      font-weight: 500;
      padding: 12px 20px;
      font-size: 14px;
      border-radius: 4px;
      text-decoration-line: none;
      color: #fff;
    }
  }
}
</style>
